import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { providerState, useGetProvider } from "apis";
import { Button, DetailsCard, Header, Loading } from "components";
import { useEffect, useState } from "react";
import { app, auth, db } from "../../App";
import { useRecoilValue } from "recoil";

import "./account.css";

const Account = () => {
  const nav = useIonRouter();
  const provider = useRecoilValue(providerState);

  useGetProvider(db, auth.currentUser?.uid);

  if (!provider) return <Loading />;

  return (
    <IonPage>
      <Header label="Account settings" />
      <IonContent>
        <DetailsCard
          db={db}
          provider={provider}
          uid={auth.currentUser?.uid}
          showEdit={true}
          fetch={fetch}
          app={app}
        >
          <div className="accounts-btns">
            <Button
              expand
              size="small"
              color="primary"
              label="Account Settings"
              onClick={() => {
                nav.push("/account/settings", "forward");
              }}
            />
            <Button
              expand
              size="small"
              color="secondary"
              label="Edit services"
              onClick={() => {
                nav.push("/account/services", "forward");
              }}
            />
          </div>
        </DetailsCard>
      </IonContent>
    </IonPage>
  );
};

export default Account;
