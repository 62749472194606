import {
  onSnapshot,
  addDoc,
  collection,
  getDoc,
  doc,
} from "firebase/firestore";
import { db, getStripe } from "../App";

export async function createCheckoutSession(uid: string) {
  const providerRef = await getDoc(doc(db, "providers", uid));
  const checkoutSessionRef = await addDoc(
    collection(db, "providers", uid, "checkout_sessions"),
    {
      price:
        process.env.NODE_ENV === "development"
          ? "price_1Mrv5hLwLxlVe09Cir2Y22Os"
          : "price_1NKp0rLwLxlVe09ClQK5kJq6",
      success_url:
        window.location.origin +
        (providerRef.exists() && providerRef?.data().isComplete
          ? "/login"
          : "/register"),
      cancel_url: window.location.origin + "/payment",
    }
  );

  onSnapshot(checkoutSessionRef, {
    next: async (snap) => {
      const { sessionId } = snap.data() as any;
      if (sessionId) {
        const stripe = await getStripe();
        stripe?.redirectToCheckout({ sessionId });
      }
    },
  });
}
