/* eslint-disable @next/next/no-img-element */
/* eslint-disable turbo/no-undeclared-env-vars */
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonNote,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonSlide,
  IonSlides,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Button, Loading } from "components";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import AutoComplete from "react-google-autocomplete";
import { E164Number } from "libphonenumber-js/types";
import PhoneInput, {
  formatPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  categoriesState,
  paymentMethodsState,
  registerProvider,
  useGetCategories,
  useGetPaymentMethods,
} from "apis";
import { useRecoilValue } from "recoil";
import { app, auth, db } from "../../../App";
import * as yup from "yup";
import { add, arrowBack, remove } from "ionicons/icons";
import moment from "moment";
import { Camera, CameraResultType, Photo } from "@capacitor/camera";
import { GeoPoint } from "firebase/firestore";

import "./register.css";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [pic, setPic] = useState<Photo>();
  const [picErr, setPicError] = useState<string>();
  const [addressedSelected, setAddressSelected] = useState(false);
  const [slideIndex, setSlideIndex] = useState<number>();
  const [isSameDay, setIsSameDay] = useState<boolean>(true);
  const [coords, setCoords] = useState({
    lng: 0,
    lat: 0,
  });
  const [hours, setHours] = useState([
    {
      isOpen: false,
      from: moment().set("hours", 8).set("minutes", 0).format(),
      to: moment().set("hours", 16).set("minutes", 0).format(),
      break: moment().set("hours", 12).set("minutes", 0).format(),
      breakMins: undefined as undefined | string,
      days: [] as number[],
    },
  ]);
  const [hoursErr, setHoursErr] = useState<string>();
  const [daysError, setDaysErr] = useState<string>();
  const [services, setServices] = useState([
    {
      title: "",
      description: "",
      category: "",
      process: {
        isOneStep: true,
        oneStepEstMins: undefined as undefined | string,
        steps: [
          {
            step: 1,
            description: "",
            estMins: undefined as undefined | string,
            placeholder: "Wash hair",
          },
          {
            step: 2,
            description: "",
            estMins: undefined as undefined | string,
            placeholder: "Dry hair",
          },
        ],
      },
    },
  ]);
  const [servicesErr, setServicesErr] = useState<string>();

  const paymentMethods = useRecoilValue(paymentMethodsState);
  const categories = useRecoilValue(categoriesState);

  const slidesRef = useRef<HTMLIonSlidesElement>();

  const validationSchema = yup.object({
    name: yup.string().required(),
    description: yup.string(),
    phone: yup.string().min(9).required(),
    formatted: yup.string().required(),
    street: yup.string().required(),
    suite: yup.string(),
    city: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required(),
    paymentMethods: yup.array().min(1).of(yup.string()),
    daysCanBookAhead: yup.number().min(1, "Must be at least 1"),
  });
  const validateBookAhead = yup.object({
    isSameDay: yup.boolean().required(),
    daysCanBookAhead: yup.number().when("isSameDay", {
      is: false,
      then: (schema) => schema.required(),
    }),
  });
  const validateHours = yup.object({
    hours: yup.array().of(
      yup.object({
        isOpen: yup.boolean().required(),
        breakMins: yup.number().when("isOpen", {
          is: true,
          then: (schema) => schema.required(),
        }),
        days: yup.array().of(yup.number()).min(1).required(),
      })
    ),
  });
  const validateServices = yup.object({
    services: yup.array().of(
      yup.object({
        title: yup.string().required(),
        description: yup.string().required(),
        category: yup.string().required(),
        process: yup.object({
          isOneStep: yup.boolean().required(),
          oneStepEstMins: yup.number().when("isOneStep", {
            is: true,
            then: (schema) => schema.required(),
          }),
          steps: yup.array().when("isOneStep", {
            is: false,
            then: (schema) =>
              schema.of(
                yup.object({
                  description: yup.string().required(),
                  estMins: yup.number().required(),
                })
              ),
          }),
        }),
      })
    ),
  });

  async function getPhoto() {
    const img = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      allowEditing: true,
      width: 200,
    });

    setPicError(undefined);
    setPic(img);
  }

  useGetPaymentMethods(db);
  useGetCategories(db);

  useEffect(() => {
    async function getActiveIndex() {
      setSlideIndex(await slidesRef.current?.getActiveIndex());
    }
    getActiveIndex();
  }, [slideIndex]);

  if (!paymentMethods || !categories) {
    return <Loading />;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {slideIndex ? (
              <IonButton onClick={() => slidesRef.current?.slidePrev()}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButton>
            ) : null}
          </IonButtons>
          <IonTitle>Provider register</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            name: "",
            description: "",
            phone: "",
            formatted: "",
            street: "",
            suite: "",
            city: "",
            state: "",
            zip: "",
            paymentMethods: [],
            daysCanBookAhead: undefined,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            console.log(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <IonSlides
                ref={slidesRef as React.MutableRefObject<HTMLIonSlidesElement>}
                onIonSlideDidChange={async (e) =>
                  setSlideIndex(await e.target.getActiveIndex())
                }
                options={{
                  allowTouchMove: false,
                }}
              >
                <IonSlide>
                  <div className="slides-container">
                    {/* Profile picture */}
                    {picErr && (
                      <IonText color="danger">
                        <p>{picErr}</p>
                      </IonText>
                    )}
                    <div className="avatar">
                      <IonAvatar>
                        <img
                          style={{ height: 200, width: 200 }}
                          src={
                            pic
                              ? pic?.webPath
                              : "https://ionicframework.com/docs/img/demos/avatar.svg"
                          }
                          alt="Profile pic"
                        />
                      </IonAvatar>
                    </div>
                    <IonButton fill="clear" onClick={getPhoto}>
                      Upload photo
                    </IonButton>
                    {/* Name */}
                    <IonItem className="ion-invalid" lines="full">
                      <IonLabel position="floating">Your name</IonLabel>
                      <IonInput
                        type="text"
                        name="name"
                        value={values.name}
                        onIonChange={handleChange}
                        onBlur={handleBlur}
                      ></IonInput>
                      {errors.name && touched.name && (
                        <IonNote slot="error">Please enter a name.</IonNote>
                      )}
                    </IonItem>
                    {/* Description */}
                    <IonItem lines="full">
                      <IonLabel position="floating">Description/Bio</IonLabel>
                      <IonTextarea
                        name="description"
                        value={values.description}
                        onIonChange={handleChange}
                        onBlur={handleBlur}
                      ></IonTextarea>
                    </IonItem>
                    {/* Phone */}
                    <IonItem className="ion-invalid" lines="full">
                      <IonLabel position="stacked">
                        Enter your phone number:
                      </IonLabel>
                      <PhoneInput
                        name="phone"
                        placeholder="(000) 000-0000"
                        className="phone-input"
                        defaultCountry="US"
                        value={values.phone}
                        onChange={(num) => {
                          handleChange("phone")(num === undefined ? "" : num);
                          handleChange("formatted")(
                            num === undefined
                              ? ""
                              : `${
                                  parsePhoneNumber(num as string)
                                    ?.countryCallingCode
                                } ${formatPhoneNumber(num as E164Number)}`
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.phone && touched.phone && (
                        <IonText color="danger" style={{ fontSize: 13 }}>
                          <p>Please select an address from dropdown.</p>
                        </IonText>
                      )}
                    </IonItem>
                    {/* Address */}
                    <IonList>
                      <IonItem className="ion-invalid" lines="full">
                        <IonLabel position="stacked">Address</IonLabel>
                        <AutoComplete
                          id="street"
                          placeholder="Enter street"
                          style={{
                            width: "100%",
                            backgroundColor: "rgba(0,0,0,0)",
                            border: "none",
                          }}
                          inputAutocompleteValue={values.street.split(",")[0]}
                          options={{ types: ["address"] }}
                          defaultValue={values.street}
                          apiKey={process.env.REACT_APP_googleMapAPI}
                          onPlaceSelected={(place) => {
                            handleChange("street")(
                              (place.formatted_address as string).split(",")[0]
                            );
                            handleChange("city")(
                              place.address_components[3].long_name
                            );
                            handleChange("state")(
                              place.address_components[5].short_name
                            );
                            handleChange("zip")(
                              place.address_components[7].long_name
                            );
                            setCoords({
                              lng: place.geometry.location?.lng() || 0,
                              lat: place.geometry.location?.lat() || 0,
                            });
                            setAddressSelected(true);
                          }}
                          onBlur={handleBlur("street")}
                          onChange={() => {
                            setAddressSelected(false);
                          }}
                        />
                        {errors.street && touched.street && !addressedSelected && (
                          <IonText color="danger" style={{ fontSize: 13 }}>
                            <p>Please select an address from dropdown.</p>
                          </IonText>
                        )}
                      </IonItem>
                      <IonItem lines="full">
                        <IonLabel position="floating">Address 2</IonLabel>
                        <IonInput
                          name="suite"
                          value={values.suite}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Suite, apt, unit..."
                        ></IonInput>
                      </IonItem>
                    </IonList>
                    {/* Payment methods */}
                    <IonItem className="ion-invalid" lines="full">
                      <IonSelect
                        name="paymentMethods"
                        onIonChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Select all payment methods"
                        multiple
                      >
                        {paymentMethods.map((paymentMethod) => (
                          <IonSelectOption
                            key={paymentMethod.id}
                            value={paymentMethod.id}
                          >
                            {paymentMethod.label}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                      {errors.paymentMethods && touched.paymentMethods && (
                        <IonNote slot="error">
                          Please choose at least 1 payment method.
                        </IonNote>
                      )}
                    </IonItem>
                    {/* Days ahead */}
                    <p className="subtitle">
                      Number of days clients can book ahead:
                    </p>
                    <IonList>
                      <IonRadioGroup
                        value={isSameDay}
                        onIonChange={(e) => setIsSameDay(e.target.value)}
                      >
                        <IonItem>
                          <IonRadio slot="start" value={true}></IonRadio>
                          <IonLabel>Same day only</IonLabel>
                        </IonItem>
                        <IonItem lines="none">
                          <IonRadio slot="start" value={false}></IonRadio>
                          <IonLabel>Allow booking ahead</IonLabel>
                        </IonItem>
                      </IonRadioGroup>
                      <IonItem
                        disabled={isSameDay}
                        lines="full"
                        className="ion-invalid"
                      >
                        <IonLabel>Days ahead:</IonLabel>
                        <IonInput
                          name="daysCanBookAhead"
                          type="number"
                          value={values.daysCanBookAhead}
                          onIonChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter here"
                        ></IonInput>
                        {errors.daysCanBookAhead &&
                          touched.daysCanBookAhead && (
                            <IonNote slot="error">Must be at least 1.</IonNote>
                          )}
                      </IonItem>
                    </IonList>

                    <Button
                      className="next-btn"
                      expand
                      label="Continue"
                      loading={loading}
                      disabled={loading}
                      onClick={async () => {
                        setLoading(true);
                        try {
                          await validateBookAhead.validate({
                            isSameDay,
                            daysCanBookAhead: values.daysCanBookAhead,
                          });
                          if (Object.keys(errors).length || !pic) {
                            if (!pic)
                              setPicError("Please upload a profile picture.");
                            window.scrollTo(0, 0);
                          } else {
                            slidesRef.current?.slideNext();
                          }
                        } catch (error) {
                          console.log(error);
                        }
                        setLoading(false);
                      }}
                      color="primary"
                    />
                  </div>
                </IonSlide>

                {/* Hours */}
                <IonSlide>
                  <div className="slides-container">
                    {hoursErr && (
                      <IonText color="danger">
                        <p>{hoursErr}</p>
                      </IonText>
                    )}
                    <h6 className="subtitle">Operating hours:</h6>
                    {hours.map((hour, index) => (
                      <div key={index}>
                        <IonList>
                          <IonRadioGroup
                            allowEmptySelection={false}
                            value={hour.isOpen}
                            onIonChange={(e) => {
                              setHours(
                                hours.map((r, i) =>
                                  i === index
                                    ? { ...r, isOpen: e.target.value }
                                    : r
                                )
                              );
                            }}
                          >
                            <IonItem>
                              <IonRadio slot="start" value={false}></IonRadio>
                              <IonLabel>Closed</IonLabel>
                            </IonItem>
                            <IonItem lines="none">
                              <IonRadio slot="start" value={true}></IonRadio>
                              <IonLabel>Open</IonLabel>
                            </IonItem>
                          </IonRadioGroup>
                          <IonItem disabled={!hours[index].isOpen} lines="none">
                            <IonItem lines="none">
                              <p>From:</p>
                              <IonDatetimeButton
                                datetime={`from${index}`}
                              ></IonDatetimeButton>
                            </IonItem>
                            <IonItem lines="none">
                              <p>To:</p>
                              <IonDatetimeButton
                                datetime={`to${index}`}
                              ></IonDatetimeButton>
                            </IonItem>
                          </IonItem>
                          <IonItem disabled={!hours[index].isOpen}>
                            <IonItem lines="none">
                              <p>Break: </p>
                              <IonDatetimeButton
                                datetime={`break${index}`}
                              ></IonDatetimeButton>
                            </IonItem>
                            <IonItem>
                              <IonLabel position="stacked">Duration:</IonLabel>
                              <IonInput
                                placeholder="in mins"
                                type="number"
                                onIonChange={(e) =>
                                  setHours(
                                    hours.map((r, i) =>
                                      i === index
                                        ? {
                                            ...r,
                                            breakMins:
                                              e.target.value === ""
                                                ? undefined
                                                : (e.target.value as undefined),
                                          }
                                        : r
                                    )
                                  )
                                }
                              ></IonInput>
                            </IonItem>
                          </IonItem>
                          <IonItem className="ion-invalid" lines="full">
                            <IonLabel>Select days</IonLabel>
                            <IonSelect
                              onIonChange={(e) => {
                                setHours(
                                  hours.map((r, i) =>
                                    i === index
                                      ? { ...r, days: e.target.value }
                                      : r
                                  )
                                );
                              }}
                              multiple
                            >
                              <IonSelectOption value={0}>
                                Sunday
                              </IonSelectOption>
                              <IonSelectOption value={1}>
                                Monday
                              </IonSelectOption>
                              <IonSelectOption value={2}>
                                Tuesday
                              </IonSelectOption>
                              <IonSelectOption value={3}>
                                Wednesday
                              </IonSelectOption>
                              <IonSelectOption value={4}>
                                Thursday
                              </IonSelectOption>
                              <IonSelectOption value={5}>
                                Friday
                              </IonSelectOption>
                              <IonSelectOption value={6}>
                                Saturday
                              </IonSelectOption>
                            </IonSelect>
                            {daysError && (
                              <IonNote slot="error">{daysError}</IonNote>
                            )}
                          </IonItem>
                        </IonList>

                        <IonModal keepContentsMounted={true}>
                          <IonDatetime
                            value={hours[index].from}
                            onIonChange={(e) =>
                              setHours(
                                hours.map((r, i) =>
                                  i === index
                                    ? { ...r, from: e.target.value as string }
                                    : r
                                )
                              )
                            }
                            id={`from${index}`}
                            presentation="time"
                          ></IonDatetime>
                        </IonModal>
                        <IonModal keepContentsMounted={true}>
                          <IonDatetime
                            value={hours[index].to}
                            onIonChange={(e) =>
                              setHours(
                                hours.map((r, i) =>
                                  i === index
                                    ? { ...r, to: e.target.value as string }
                                    : r
                                )
                              )
                            }
                            id={`to${index}`}
                            presentation="time"
                          ></IonDatetime>
                        </IonModal>
                        <IonModal keepContentsMounted={true}>
                          <IonDatetime
                            value={hours[index].break}
                            onIonChange={(e) =>
                              setHours(
                                hours.map((r, i) =>
                                  i === index
                                    ? { ...r, break: e.target.value as string }
                                    : r
                                )
                              )
                            }
                            id={`break${index}`}
                            presentation="time"
                          ></IonDatetime>
                        </IonModal>
                      </div>
                    ))}

                    <div className="horizontal-btns">
                      {hours.length > 1 && (
                        <IonButton
                          fill="clear"
                          onClick={() =>
                            setHours(
                              hours.filter((_, i) => i !== hours.length - 1)
                            )
                          }
                        >
                          <IonIcon slot="icon-only" icon={remove}></IonIcon>
                        </IonButton>
                      )}
                      {hours.length < 7 && (
                        <IonButton
                          fill="clear"
                          onClick={() => {
                            setHours([
                              ...hours,
                              {
                                isOpen: false,
                                from: moment()
                                  .set("hours", 8)
                                  .set("minutes", 0)
                                  .format(),
                                to: moment()
                                  .set("hours", 16)
                                  .set("minutes", 0)
                                  .format(),
                                break: moment()
                                  .set("hours", 12)
                                  .set("minutes", 0)
                                  .format(),
                                breakMins: undefined,
                                days: [],
                              },
                            ]);
                          }}
                        >
                          <IonIcon slot="icon-only" icon={add}></IonIcon>
                        </IonButton>
                      )}
                    </div>

                    <Button
                      className="next-btn"
                      expand
                      loading={loading}
                      disabled={loading}
                      label="Continue"
                      onClick={async () => {
                        setLoading(true);
                        setDaysErr(undefined);
                        setHoursErr(undefined);
                        const days = hours.flatMap((s) => s.days);
                        const seen = new Set();
                        try {
                          await validateHours.validate({ hours });
                          if (
                            days.filter((n) => seen.size === seen.add(n).size)
                              .length
                          )
                            setDaysErr("Duplicate days selected.");
                          else if (days.length < 7)
                            setDaysErr("Check that all days are selected.");
                          else slidesRef.current?.slideNext();
                        } catch (error) {
                          setHoursErr(
                            "Please check all inputs are filled correctly."
                          );
                        }
                        setLoading(false);
                      }}
                      color="primary"
                    />
                  </div>
                </IonSlide>

                {/* Services */}
                <IonSlide>
                  <div className="slides-container">
                    {servicesErr && (
                      <IonText color="danger">
                        <p>{servicesErr}</p>
                      </IonText>
                    )}
                    <h6 className="subtitle">Services</h6>
                    <div>
                      {services.map((service, index) => (
                        <div key={index}>
                          <IonList>
                            <IonItem lines="full">
                              <IonLabel position="floating">
                                Service name
                              </IonLabel>
                              <IonInput
                                placeholder="Short haircut, long braids, wax, etc..."
                                onIonChange={(e) =>
                                  setServices(
                                    services.map((r, i) =>
                                      index === i
                                        ? {
                                            ...r,
                                            title: e.target.value as string,
                                          }
                                        : r
                                    )
                                  )
                                }
                              ></IonInput>
                            </IonItem>
                            <IonItem lines="full">
                              <IonLabel position="floating">
                                Description
                              </IonLabel>
                              <IonInput
                                placeholder=""
                                onIonChange={(e) =>
                                  setServices(
                                    services.map((r, i) =>
                                      index === i
                                        ? {
                                            ...r,
                                            description: e.target
                                              .value as string,
                                          }
                                        : r
                                    )
                                  )
                                }
                              ></IonInput>
                            </IonItem>
                            <IonItem lines="full">
                              <IonLabel>Category of service</IonLabel>
                              <IonSelect
                                placeholder="Select"
                                onIonChange={(e) =>
                                  setServices(
                                    services.map((r, i) =>
                                      index === i
                                        ? {
                                            ...r,
                                            category: e.target.value as string,
                                          }
                                        : r
                                    )
                                  )
                                }
                              >
                                {categories.map((category) => (
                                  <IonSelectOption
                                    key={category.id}
                                    value={category.id}
                                  >
                                    {category.title}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                            <p className="subtitle">Steps:</p>
                            <IonRadioGroup
                              onIonChange={(e) =>
                                setServices(
                                  services.map((s, i) =>
                                    index === i
                                      ? {
                                          ...s,
                                          process: {
                                            ...s.process,
                                            isOneStep: e.target.value,
                                          },
                                        }
                                      : s
                                  )
                                )
                              }
                              value={service.process.isOneStep}
                            >
                              <IonItem lines="none">
                                <IonRadio slot="start" value={true}></IonRadio>
                                <IonLabel>
                                  <h3>Single step process</h3>
                                  <p>i.e. haircut, eyebrow threading, etc.</p>
                                </IonLabel>
                              </IonItem>
                            </IonRadioGroup>
                            <IonItem disabled={!service.process.isOneStep}>
                              <IonLabel>Estimated time</IonLabel>
                              <IonInput
                                type="number"
                                placeholder="in mins"
                                onIonChange={(e) =>
                                  setServices(
                                    services.map((s, i) =>
                                      i === index
                                        ? {
                                            ...s,
                                            process: {
                                              ...s.process,
                                              oneStepEstMins:
                                                e.target.value === ""
                                                  ? undefined
                                                  : (e.target
                                                      .value as undefined),
                                            },
                                          }
                                        : s
                                    )
                                  )
                                }
                              ></IonInput>
                            </IonItem>
                            <IonRadioGroup
                              onIonChange={(e) =>
                                setServices(
                                  services.map((s, i) =>
                                    index === i
                                      ? {
                                          ...s,
                                          process: {
                                            ...s.process,
                                            isOneStep: e.target.value,
                                          },
                                        }
                                      : s
                                  )
                                )
                              }
                              value={service.process.isOneStep}
                            >
                              <IonItem lines="none">
                                <IonRadio slot="start" value={false}></IonRadio>
                                <IonLabel>
                                  <h3>Multi-step process</h3>
                                  <p>
                                    i.e. braidng (wash hair, dry hair, then
                                    braid)
                                  </p>
                                </IonLabel>
                              </IonItem>
                            </IonRadioGroup>
                            {service.process.steps.map((step, idx) => (
                              <IonList key={idx}>
                                <IonItem disabled={service.process.isOneStep}>
                                  <IonLabel>Step {idx + 1}:</IonLabel>
                                  <IonInput
                                    placeholder={step.placeholder}
                                    onIonChange={(e) =>
                                      setServices(
                                        services.map((s, i) =>
                                          i === index
                                            ? {
                                                ...s,
                                                process: {
                                                  ...s.process,
                                                  steps: s.process.steps.map(
                                                    (st, ind) =>
                                                      ind === idx
                                                        ? {
                                                            ...st,
                                                            description: e
                                                              .target
                                                              .value as string,
                                                          }
                                                        : st
                                                  ),
                                                },
                                              }
                                            : s
                                        )
                                      )
                                    }
                                  ></IonInput>
                                </IonItem>
                                <IonItem
                                  lines="full"
                                  disabled={service.process.isOneStep}
                                >
                                  <IonLabel>Estimated time</IonLabel>
                                  <IonInput
                                    type="number"
                                    placeholder="in mins"
                                    onIonChange={(e) =>
                                      setServices(
                                        services.map((s, i) =>
                                          i === index
                                            ? {
                                                ...s,
                                                process: {
                                                  ...s.process,
                                                  steps: s.process.steps.map(
                                                    (st, ind) =>
                                                      ind === idx
                                                        ? {
                                                            ...st,
                                                            estMins:
                                                              e.target.value ===
                                                              ""
                                                                ? undefined
                                                                : (e.target
                                                                    .value as undefined),
                                                          }
                                                        : st
                                                  ),
                                                },
                                              }
                                            : s
                                        )
                                      )
                                    }
                                  ></IonInput>
                                </IonItem>
                              </IonList>
                            ))}
                            <IonItem
                              lines="full"
                              disabled={service.process.isOneStep}
                            >
                              <div className="horizontal-btns">
                                {service.process.steps.length > 2 && (
                                  <IonButton
                                    fill="clear"
                                    onClick={() =>
                                      setServices(
                                        services.map((s, ind) =>
                                          index === ind
                                            ? {
                                                ...s,
                                                process: {
                                                  ...s.process,
                                                  steps: s.process.steps.filter(
                                                    (_, i) =>
                                                      i !==
                                                      s.process.steps.length - 1
                                                  ),
                                                },
                                              }
                                            : s
                                        )
                                      )
                                    }
                                  >
                                    <IonIcon
                                      slot="icon-only"
                                      icon={remove}
                                    ></IonIcon>
                                  </IonButton>
                                )}

                                <IonButton
                                  fill="clear"
                                  onClick={() => {
                                    setServices(
                                      services.map((s, ind) =>
                                        index === ind
                                          ? {
                                              ...s,
                                              process: {
                                                ...s.process,
                                                steps: [
                                                  ...s.process.steps,
                                                  {
                                                    step:
                                                      s.process.steps.length +
                                                      1,
                                                    description: "",
                                                    estMins: undefined,
                                                    placeholder: "Braid hair",
                                                  },
                                                ],
                                              },
                                            }
                                          : s
                                      )
                                    );
                                  }}
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={add}
                                  ></IonIcon>
                                </IonButton>
                              </div>
                            </IonItem>
                          </IonList>
                        </div>
                      ))}
                      <div className="horizontal-btns">
                        {services.length > 1 && (
                          <IonButton
                            fill="clear"
                            onClick={() =>
                              setServices(
                                services.filter(
                                  (_, i) => i === services.length - 1
                                )
                              )
                            }
                          >
                            <IonIcon slot="icon-only" icon={remove}></IonIcon>
                          </IonButton>
                        )}

                        <IonButton
                          fill="clear"
                          onClick={() =>
                            setServices([
                              ...services,
                              {
                                title: "",
                                description: "",
                                category: "",
                                process: {
                                  isOneStep: true,
                                  oneStepEstMins: undefined,
                                  steps: [
                                    {
                                      step: 1,
                                      description: "",
                                      estMins: undefined,
                                      placeholder: "Wash hair",
                                    },
                                    {
                                      step: 2,
                                      description: "",
                                      estMins: undefined,
                                      placeholder: "Dry hair",
                                    },
                                  ],
                                },
                              },
                            ])
                          }
                        >
                          <IonIcon slot="icon-only" icon={add}></IonIcon>
                        </IonButton>
                      </div>
                    </div>

                    <Button
                      className="next-btn"
                      expand
                      loading={loading}
                      disabled={loading}
                      label="Finish"
                      onClick={async () => {
                        function getHours(num: number) {
                          const hour = hours.find((h) => h.days.includes(num));
                          const fromTime = new Date(hour?.from || "");
                          const toTime = new Date(hour?.to || "");

                          return hour?.isOpen
                            ? {
                                from: {
                                  hour: fromTime.getHours(),
                                  mins: fromTime.getMinutes(),
                                },
                                to: {
                                  hour: toTime.getHours(),
                                  mins: toTime.getMinutes(),
                                },
                              }
                            : null;
                        }
                        function getBreak(num: number) {
                          const hour = hours.find((h) => h.days.includes(num));
                          const breakTime = new Date(hour?.break || "");

                          return hour?.isOpen
                            ? {
                                time: {
                                  hour: breakTime.getHours(),
                                  mins: breakTime.getMinutes(),
                                },
                                duration: parseInt(hour.breakMins || "0"),
                              }
                            : null;
                        }
                        setLoading(true);
                        setServicesErr(undefined);
                        try {
                          await validateServices.validate({ services });
                          await registerProvider(
                            app,
                            fetch,
                            db,
                            {
                              isComplete: true,
                              profilePic: pic?.webPath || "",
                              name: values.name,
                              email: auth.currentUser?.email || "",
                              description: values.description,
                              categories: services.flatMap((s) => s.category),
                              address: {
                                street: values.street,
                                suite: values.suite,
                                city: values.city,
                                state: values.state,
                                zip: values.zip,
                                coords: new GeoPoint(coords.lat, coords.lng),
                              },
                              paymentMethodsIds: values.paymentMethods,
                              hours: {
                                0: getHours(0),
                                1: getHours(1),
                                2: getHours(2),
                                3: getHours(3),
                                4: getHours(4),
                                5: getHours(5),
                                6: getHours(6),
                              },
                              break: {
                                0: getBreak(0),
                                1: getBreak(1),
                                2: getBreak(2),
                                3: getBreak(3),
                                4: getBreak(4),
                                5: getBreak(5),
                                6: getBreak(6),
                              },
                              isOpen: false,
                              daysCanBookAhead: isSameDay
                                ? 0
                                : values.daysCanBookAhead || 1,
                              currLineTime: 0,
                              phone: {
                                formatted: values.formatted,
                                forUse: values.phone,
                              },
                            },
                            services.map((s) => ({
                              title: s.title,
                              description: s.description,
                              categoryId: s.category,
                              process: s.process.isOneStep
                                ? [
                                    {
                                      step: 1,
                                      description: s.description,
                                      estMins: parseInt(
                                        s.process.oneStepEstMins || "0"
                                      ),
                                    },
                                  ]
                                : s.process.steps.map((step) => ({
                                    step: step.step,
                                    description: step.description,
                                    estMins: parseInt(step.estMins || "0"),
                                  })),
                              totalEstMins: s.process.isOneStep
                                ? parseInt(s.process.oneStepEstMins || "0")
                                : s.process.steps.reduce(
                                    (prev, curr) =>
                                      prev + parseInt(curr.estMins || "0"),
                                    0
                                  ),
                            })),
                            auth.currentUser?.uid || ""
                          );
                          await auth.currentUser?.reload();
                          window.location.reload();
                        } catch (error) {
                          console.log(error)
                          if (
                            (error as yup.ValidationError).errors === undefined
                          )
                            setServicesErr(
                              "Please check all inputs are filled correctly."
                            );
                          else
                            setServicesErr(
                              "Error occured. Please try again later."
                            );
                          setLoading(false);
                        }
                      }}
                      color="primary"
                    />
                  </div>
                </IonSlide>
              </IonSlides>
            );
          }}
        </Formik>
      </IonContent>
    </IonPage>
  );
};

export default Register;
