import { IonButton, IonContent, IonPage } from "@ionic/react"
import { Header } from "components"
import { auth } from "../../../App";

const VerifyEmail = () => {

  return(
    <IonPage>
      <Header label="Verify email" />
      <IonContent>
        <div>
          <p style={{textAlign: "center"}}>A verification email has been sent.</p>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default VerifyEmail;