import { useEffect } from "react";
import { atom, useSetRecoilState } from "recoil";
import { auth, db } from "../App";
import { getDoc, doc } from "firebase/firestore";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import {
  signInWithCredential,
  AuthCredential,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { providerState } from "apis";
import { IProvider } from "interfaces";

export const authState = atom<number[] | undefined>({
  key: "authState",
  default: undefined,
});
export const authErrorState = atom<Error | undefined>({
  key: "authErrorState",
  default: undefined,
});

export const onLogIn = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    // console.log(result.credential)
    // if(result?.credential)
    //   await signInWithCredential(auth, result?.credential as AuthCredential)
  } catch (error) {
    console.log(error);
    throw new Error("Email already exists.");
  }
};

export const handleEmailVerify = async (oobCode: string) => {
  console.log(oobCode);
  await FirebaseAuthentication.applyActionCode({ oobCode });
};

export const onSignUp = async (email: string, password: string) => {
  try {
    const user = await FirebaseAuthentication.createUserWithEmailAndPassword({
      email,
      password,
    });
    if (user.user) await FirebaseAuthentication.sendEmailVerification();
    else throw new Error();
  } catch (error) {
    console.log(error);
    throw new Error("Email already exists.");
  }
};
export const useAuth = () => {
  const setAuth = useSetRecoilState(authState);
  const setAuthError = useSetRecoilState(authErrorState);
  const setProvider = useSetRecoilState(providerState);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(
      async (user) => {
        if (user) {
          await user.getIdToken();
          const token = await user.getIdTokenResult();
          const snap = await getDoc(doc(db, "providers", user.uid));

          const authArr = [];
          if (user.emailVerified) authArr.push(0);
          if (token.claims.stripeRole) authArr.push(1);
          if ((snap.data() as IProvider)?.isComplete) authArr.push(2);
          setAuth(authArr);
        } else {
          setAuth([]);
        }
        setAuthError(undefined);
      },
      (error) => {
        setAuth(undefined);
        setAuthError(error);
      }
    );
    return () => {
      unsub();
    };
  }, [setAuth, setAuthError, setProvider]);
};
