import {
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonReorderGroup,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { providerState, todayQueueState, useGetProvider } from "apis";
import { Button, Loading, MehuLogo } from "components";
import { auth, db } from "../../App";
import { useRecoilValue } from "recoil";
import { updateDoc, doc, writeBatch } from "firebase/firestore";
import { colors } from "utilities";
import "./home.css";
import ActiveSlot from "../../components/ActiveSlot/ActiveSlot";
import Slot from "../../components/Slot/Slot";
import { Book } from "interfaces";
import FabButton from "../../components/FabButton/FabButton";
import moment from "moment";
import { tz } from "moment-timezone";
import { useEffect, useState } from "react";
import { PushNotifications } from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";

const Home = () => {
  const [presentAlert] = useIonAlert();

  const provider = useRecoilValue(providerState);
  const queue = useRecoilValue(todayQueueState);

  const [loading, setLoading] = useState(false);

  useGetProvider(db, auth.currentUser?.uid);
  useEffect(() => {
    if (
      provider?.isComplete &&
      (provider?.zoneOffset?.offset !== new Date().getTimezoneOffset() ||
        provider?.zoneOffset?.zone !== tz.guess())
    )
      updateDoc(doc(db, "providers", provider.id), {
        zoneOffset: {
          offset: new Date().getTimezoneOffset(),
          zone: tz.guess(),
        },
      });
    async function requestUserPermission() {
      try {
        let permStatus = await PushNotifications.checkPermissions();
        if (permStatus.receive === "prompt") {
          permStatus = await PushNotifications.requestPermissions();
        }
        if (permStatus.receive !== "granted") {
          throw new Error("User denied permissions!");
        }

        await PushNotifications.register();
      } catch (error) {
        console.log(error);
      }
    }
    async function registerToken() {
      PushNotifications.addListener("registration", (token) => {
        if (provider && provider.isComplete)
          updateDoc(doc(db, "providers", provider.id), { token: token.value });
      });
    }
    if (Capacitor.isNativePlatform()) {
      requestUserPermission();
      registerToken();
    }
    return () => {
      if (Capacitor.isNativePlatform()) PushNotifications.removeAllListeners();
    };
  }, [provider]);
  useEffect(() => {
    const todayHour = provider?.hours[moment().day()];
    if (
      queue !== undefined &&
      queue !== "empty" &&
      todayHour &&
      !provider.isOpen
    ) {
      const mins = queue.line.reduce(
        (prev, curr) =>
          prev +
          (curr.bookType === "break" ? curr.mins : curr.service.totalEstMins),
        0
      );

      const diff = moment(queue.line[0].frontTimestamp?.toMillis())
        .add(mins, "minutes")
        .diff(
          moment().hour(todayHour.to.hour).minute(todayHour.to.mins),
          "hour"
        );

      if (diff && diff >= 1)
        presentAlert(
          `Your line is ${diff} hours over your closing time. Would you like to close?`,
          [
            {
              text: "No",
              role: "cancel",
            },
            {
              text: "Yes",
              role: "confirm",
              handler: async () => {
                await updateDoc(doc(db, "providers", provider.id), {
                  isOpen: false,
                });
              },
            },
          ]
        );
    }
  }, [queue, provider, presentAlert]);

  if (!provider || !queue) return <Loading />;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <MehuLogo fill={colors.primary} width={20} />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container">
          <IonSegment
            className="segments"
            onIonChange={(e) =>
              updateDoc(doc(db, "providers", auth.currentUser?.uid || ""), {
                isOpen: e.target.value === "true",
              })
            }
            value={provider.isOpen.toString()}
          >
            <IonSegmentButton value="true">
              <IonLabel>Open</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="false">
              <IonLabel>Close</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {queue !== "empty" &&
            queue !== undefined &&
            queue.line.some(
              (b) => b.bookType !== "break" && !b.party.isApproved
            ) && (
              <IonText color="danger">
                <p>You have unapproved spots.</p>
              </IonText>
            )}
          {queue === "empty" || !queue.line.length ? (
            <p style={{ textAlign: "center" }}>Queue empty</p>
          ) : (
            <>
              {!provider.isOpen && (
                <Button
                  expand
                  color="primary"
                  label="Clear line"
                  onClick={async () => {
                    await presentAlert(
                      "Clear your line for today? This will also notify everyone in line you've closed for the day.",
                      [
                        {
                          text: "Cancel",
                          role: "cancel",
                        },
                        {
                          text: "Yes",
                          role: "confirm",
                          handler: async () => {
                            const batch = writeBatch(db);
                            queue.line.forEach((b) =>
                              batch.delete(
                                doc(
                                  db,
                                  "providers",
                                  provider.id,
                                  "queues",
                                  b.id
                                )
                              )
                            );
                            batch.delete(
                              doc(
                                db,
                                "providers",
                                provider.id,
                                "queues",
                                queue.head.id
                              )
                            );
                            await batch.commit();
                          },
                        },
                      ]
                    );
                  }}
                />
              )}
              <IonReorderGroup
                disabled={loading}
                onIonItemReorder={async (e) => {
                  setLoading(true);
                  const line = [...queue.head.line];
                  e.detail.complete(line);
                  await updateDoc(
                    doc(db, "providers", provider.id, "queues", queue.head.id),
                    { line }
                  );
                  setLoading(false);
                }}
              >
                {queue.line.map((book, index) => (
                  <div key={index}>
                    {(index === 0 && book.bookType === "break") ||
                    (book.bookType !== "break" && book.hasStarted) ? (
                      <ActiveSlot
                        index={index}
                        provider={provider}
                        queue={queue}
                        book={book}
                      />
                    ) : (
                      <Slot
                        index={index}
                        provider={provider}
                        queue={queue}
                        book={book}
                        showStart={
                          (index === 0 ||
                            (index === 1 &&
                              queue.line[index - 1].bookType === "break") ||
                            (queue.line[index - 1].bookType !== "break" &&
                              (queue.line[index - 1] as Book).hasStarted)) &&
                          book.bookType !== "break" &&
                          book.party.isApproved
                        }
                      />
                    )}
                  </div>
                ))}
              </IonReorderGroup>
            </>
          )}
        </div>
        <FabButton
          queue={queue === "empty" ? moment().format().split("T")[0] : queue}
          provider={provider}
        />
      </IonContent>
    </IonPage>
  );
};

export default Home;
