import {
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import { Button, Header, Modal } from "components";
import { useState } from "react";
import { auth } from "../../../App";
import {
  updateEmail,
  updatePassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import * as yup from "yup";

const Settings = () => {
  const nav = useIonRouter();
  const [presentAlert] = useIonAlert();
  const [loading, setloading] = useState(false);
  const [value, setValue] = useState<
    Partial<{
      email: string;
      confirmEmail: string;
      password: string;
      newPassword: string;
      confirmPassword: string;
    }>
  >({});
  const [err, setErr] = useState<string>();
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);

  const validateEmail = yup.object({
    email: yup.string().email().required(),
    confirmEmail: yup
      .string()
      .oneOf([yup.ref("email")])
      .required(),
  });
  const validatePassword = yup.object({
    password: yup.string().required(),
    newPassword: yup.string().min(8).required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")])
      .required(),
  });

  return (
    <IonPage>
      <Header label="Account settings" />
      <IonContent>
        <IonList>
          <IonItem
            lines="full"
            button
            detail={true}
            onClick={() => {
              setValue({
                email: "",
                confirmEmail: "",
              });
              setEmailModalOpen(true);
            }}
          >
            <IonLabel>
              <h3>Email</h3>
              <p>{auth.currentUser?.email}</p>
            </IonLabel>
          </IonItem>
          <IonItem
            lines="full"
            button
            detail={true}
            onClick={() => {
              setValue({
                password: "",
                newPassword: "",
                confirmPassword: "",
              });
              setPasswordModalOpen(true);
            }}
          >
            <IonLabel>
              <h3>Password</h3>
              <p>*******</p>
            </IonLabel>
          </IonItem>
        </IonList>
        <div style={{ padding: "1rem" }}>
          <Button
            expand
            loading={loading}
            disabled={loading}
            color="primary"
            label="Sign out"
            onClick={async () => {
              presentAlert({
                header: "Sign out?",
                message: "Are you sure you want to sign out?",
                buttons: [
                  {
                    text: "Cancel",
                    role: "cancel",
                  },
                  {
                    text: "Sign out",
                    role: "confirm",
                    handler: async () => {
                      setloading(true);
                      await auth.signOut();
                      nav.push("/", "root");
                      setloading(false);
                    },
                  },
                ],
              });
            }}
          />
        </div>
      </IonContent>

      {/* Email Modal */}
      <Modal
        isOpen={emailModalOpen}
        setOpen={setEmailModalOpen}
        title="Edit Email"
      >
        {err && (
          <IonText color="danger">
            <p>{err}</p>
          </IonText>
        )}
        <IonList>
          <IonItem lines="full">
            <IonLabel position="floating">New email</IonLabel>
            <IonInput
              value={value.email}
              onIonChange={(e) =>
                setValue({ ...value, email: e.target.value as string })
              }
            ></IonInput>
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="floating">Confirm new email</IonLabel>
            <IonInput
              value={value.confirmEmail}
              onIonChange={(e) =>
                setValue({ ...value, confirmEmail: e.target.value as string })
              }
            ></IonInput>
          </IonItem>
        </IonList>
        <Button
          loading={loading}
          disabled={loading}
          expand
          color="primary"
          label="Save"
          onClick={async () => {
            setErr(undefined);
            if (auth.currentUser) {
              try {
                await validateEmail.validate(value);
                await updateEmail(auth.currentUser, value.email as string);
                setEmailModalOpen(false);
              } catch (error) {
                if ((error as any).code === "auth/requires-recent-login")
                  setErr(
                    "Changing email requires recent login. Please sign out and sign back in."
                  );
                else {
                  setErr("Please make sure fields are valid.");
                }
              }
            }
          }}
        />
      </Modal>
      {/* Password Modal */}
      <Modal
        isOpen={passwordModalOpen}
        setOpen={setPasswordModalOpen}
        title="Edit password"
      >
        {err && (
          <IonText color="danger">
            <p>{err}</p>
          </IonText>
        )}
        <IonList>
          <IonItem lines="full">
            <IonLabel position="floating">Old password</IonLabel>
            <IonInput
              type="password"
              value={value.password}
              onIonChange={(e) =>
                setValue({ ...value, password: e.target.value as string })
              }
            ></IonInput>
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="floating">New password</IonLabel>
            <IonInput
              type="password"
              value={value.newPassword}
              onIonChange={(e) =>
                setValue({ ...value, newPassword: e.target.value as string })
              }
            ></IonInput>
          </IonItem>
          <IonItem lines="full">
            <IonLabel position="floating">Confirm new password</IonLabel>
            <IonInput
              type="password"
              value={value.confirmPassword}
              onIonChange={(e) =>
                setValue({
                  ...value,
                  confirmPassword: e.target.value as string,
                })
              }
            ></IonInput>
          </IonItem>
        </IonList>
        <Button
          loading={loading}
          disabled={loading}
          expand
          color="primary"
          label="Save"
          onClick={async () => {
            try {
              if (auth.currentUser) {
                await signInWithEmailAndPassword(
                  auth,
                  auth.currentUser?.email || "",
                  value.password || ""
                );
                await updatePassword(auth.currentUser, value.newPassword || "");
                setPasswordModalOpen(false);
                presentAlert("Email changed, please verify email.")
              }
            } catch (error) {
              console.log((error as any).code);
              if ((error as any).code === "auth/requires-recent-login")
                setErr(
                  "Changing password requires recent login. Please sign out and sign back in."
                );
              else if ((error as any).code === "auth/wrong-password") {
                setErr("Wrong password.");
              } else {
                setErr("Please make sure fields are valid.");
              }
            }
          }}
        />
      </Modal>
    </IonPage>
  );
};

export default Settings;
