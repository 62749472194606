import {
  IonItemSliding,
  IonReorder,
  IonItemOptions,
  IonItemOption,
  IonLabel,
  IonIcon,
  useIonAlert,
  useIonActionSheet,
  useIonPopover,
  IonContent,
  IonButton,
  IonPopover,
} from "@ionic/react";
import { leaveLine } from "apis";
import { Button, SlotShell } from "components";
import { Book, Break, IProvider, IQueue, ScheduledTextDTO } from "interfaces";
import {
  call,
  checkmark,
  close,
  closeOutline,
  warningOutline,
} from "ionicons/icons";
import { app, db } from "../../App";
import { updateDoc, doc, addDoc, collection } from "firebase/firestore";
import { httpsCallable, getFunctions } from "firebase/functions";
import { useState } from "react";
import moment from "moment";

const Slot: React.FC<{
  book: Book | Break;
  showStart: boolean;
  queue: IQueue;
  provider: IProvider;
  index: number;
}> = ({ book, showStart, queue, provider, index }) => {
  const [presentAlert] = useIonAlert();
  const [presentActionSheet] = useIonActionSheet();
  const [loading, setLoading] = useState(false);

  async function sendOrScheduleText() {
    const nextPerson: Book | Break | undefined = queue.line[index + 1];
    if (nextPerson) {
      if (
        book.bookType !== "break" &&
        book.timestamps.length === book.service.process.length - 1 &&
        nextPerson &&
        nextPerson.bookType !== "break"
      ) {
        const customerPhone = nextPerson.customer.phone;
        const providerName = provider.name;
        if (book.service.process[book.service.process.length - 1].estMins <= 25)
          httpsCallable(
            getFunctions(),
            "nextInLineText"
          )({ customerPhone, providerName });
        else {
          const time = moment().add(
            book.service.process[book.service.process.length - 1].estMins *
              book.party.numOfPeople -
              25,
            "minute"
          );
          await addDoc(collection(db, "scheduledTexts"), {
            customerPhone: nextPerson.customer.phone,
            providerId: provider.id,
            textSent: false,
            timestamp: time.toDate(),
          } as ScheduledTextDTO);
        }
      }
    }
  }

  return (
    <IonItemSliding>
      {book.bookType !== "break" && !book.party.isApproved && (
        <div
          style={{
            position: "absolute",
            left: `${window.innerWidth / 2}px`,
            transform: "translate(-55%, 0)",
            zIndex: "1000000",
          }}
        >
          <IonButton
            fill="clear"
            onClick={() => {
              presentAlert("Are you sure you want to approve this party?", [
                { text: "No", role: "cancel" },
                {
                  text: "Yes",
                  role: "confirm",
                  handler: async () => {
                    await updateDoc(
                      doc(db, "providers", provider.id, "queues", book.id),
                      { "party.isApproved": true }
                    );
                  },
                },
              ]);
            }}
          >
            <IonIcon icon={checkmark} color="success" slot="icon-only" />
          </IonButton>
          <IonButton
            fill="clear"
            onClick={() => {
              presentAlert(
                "Are you sure you want to disapprove and remove this party?",
                [
                  { text: "No", role: "cancel" },
                  {
                    text: "Yes",
                    role: "confirm",
                    handler: async () => {
                      await leaveLine({
                        db,
                        queue,
                        provider,
                        customerId: book.id,
                      });
                      httpsCallable(
                        getFunctions(app),
                        "kick"
                      )(book.customer.phone);
                    },
                  },
                ]
              );
            }}
          >
            <IonIcon icon={closeOutline} color="danger" slot="icon-only" />
          </IonButton>
        </div>
      )}
      <SlotShell
        empty={false}
        transparent={book.bookType !== "break" && !book.party.isApproved}
      >
        {book.bookType !== "break" && book.isConfirmed === false && (
          <>
            <IonPopover trigger="popover-trigger" triggerAction="click">
              <IonContent className="ion-padding">
                Waiting on customer to re-confirm spot.
              </IonContent>
            </IonPopover>
            <IonButton id="popover-trigger" fill="clear">
              <IonIcon icon={warningOutline} color="danger" />
            </IonButton>
          </>
        )}
        <IonLabel>
          {book.bookType === "break"
            ? "Break"
            : book.customer.name +
              (book.party.numOfPeople > 1
                ? ` • ${book.party.numOfPeople} people`
                : "")}
        </IonLabel>
        {showStart && (
          <Button
            loading={loading}
            label="Start"
            color="primary"
            onClick={async () => {
              setLoading(true);
              await updateDoc(
                doc(db, "providers", provider.id, "queues", book.id),
                {
                  hasStarted: true,
                  timestamps: [new Date()],
                }
              );
              sendOrScheduleText();
              setLoading(false);
            }}
          />
        )}
        <IonReorder slot="end"></IonReorder>
      </SlotShell>
      <IonItemOptions side="end">
        {book.bookType !== "break" && (
          <IonItemOption
            onClick={() => {
              presentActionSheet({
                header: "Call or text",
                buttons: [
                  {
                    text: "Call",
                    handler: () => {
                      window.open(`tel:${book.customer.phone}`);
                    },
                  },
                  {
                    text: "Text",
                    handler: () => {
                      window.open(`sms:${book.customer.phone}`);
                    },
                  },
                ],
              });
            }}
            color="secondary"
          >
            <IonIcon slot="icon-only" icon={call} />
          </IonItemOption>
        )}
        <IonItemOption
          onClick={() => {
            presentAlert({
              header: "Kick from line?",
              message: `Are you sure you want to kick ${
                book.bookType === "break" ? "Break" : book.customer.name
              } from the line?`,
              buttons: [
                {
                  text: "Yes",
                  handler: async () => {
                    await leaveLine({
                      db,
                      queue,
                      provider,
                      customerId: book.id,
                    });
                    if (book.bookType !== "break")
                      httpsCallable(
                        getFunctions(app),
                        "kick"
                      )(book.customer.phone);
                  },
                },
                {
                  text: "Cancel",
                  role: "cancel",
                },
              ],
            });
          }}
          color="danger"
        >
          <IonIcon slot="icon-only" icon={close} />
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
};

export default Slot;
