import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonIcon,
  IonItemSliding,
  useIonAlert,
  useIonActionSheet,
} from "@ionic/react";
import { Button } from "components";
import {
  Book,
  BookingDTO,
  Break,
  IProvider,
  IQueue,
  ScheduledTextDTO,
} from "interfaces";
import { checkmark, chevronBack, chevronForward } from "ionicons/icons";
import "./activeSlot.css";
import { leaveLine } from "apis";
import { app, db } from "../../App";
import {
  addDoc,
  collection,
  updateDoc,
  doc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { httpsCallable, getFunctions } from "firebase/functions";
import { useState } from "react";
import moment from "moment";

const ActiveSlot: React.FC<{
  book: Book | Break;
  provider: IProvider;
  queue: IQueue;
  index: number;
}> = ({ book, provider, queue, index }) => {
  const [presentAlert] = useIonAlert();
  const [presentActionSheet] = useIonActionSheet();
  const [didNotify, setNotify] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  async function sendOrScheduleText() {
    const nextPerson: Book | Break | undefined = queue.line[index + 1];
    if (nextPerson) {
      if (
        book.bookType !== "break" &&
        book.timestamps.length === book.service.process.length - 1 &&
        nextPerson &&
        nextPerson.bookType !== "break"
      ) {
        const customerPhone = nextPerson.customer.phone;
        const providerName = provider.name;
        if (
          book.service.process[book.service.process.length - 1].estMins *
            book.party.numOfPeople <=
          25
        )
          httpsCallable(
            getFunctions(),
            "nextInLineText"
          )({ customerPhone, providerName });
        else {
          const time = moment().add(
            book.service.process[book.service.process.length - 1].estMins *
              book.party.numOfPeople -
              25,
            "minute"
          );
          await addDoc(collection(db, "scheduledTexts"), {
            customerPhone: nextPerson.customer.phone,
            providerId: provider.id,
            textSent: false,
            timestamp: time.toDate(),
          } as ScheduledTextDTO);
        }
      }
    }
  }

  return (
    <IonItemSliding>
      <IonCard className="active-slot-card">
        <IonCardHeader style={{ textAlign: "center" }}>
          {book.bookType === "break" ? (
            <>
              <IonCardTitle>Break</IonCardTitle>
              <IonCardSubtitle>{book.mins} mins</IonCardSubtitle>
            </>
          ) : (
            <>
              <IonCardTitle>{book.customer.name}</IonCardTitle>
              <IonCardSubtitle>{book.service.title}</IonCardSubtitle>
            </>
          )}
        </IonCardHeader>
        <IonCardContent>
          {book.bookType === "break" ? (
            <>
              <Button
                expand
                label="Done"
                color="primary"
                size="small"
                onClick={() => {
                  presentAlert({
                    header: "Complete break",
                    message: "Are you ready to get back to the bag?",
                    buttons: [
                      {
                        text: "Cancel",
                        role: "cancel",
                      },
                      {
                        text: "Yes",
                        handler: async () => {
                          try {
                            await leaveLine({
                              db,
                              provider,
                              customerId: book.id,
                              queue,
                            });
                            await addDoc(collection(db, "bookings"), {
                              bookType: "break",
                              completed: new Date(),
                              providerId: provider.id,
                            } as BookingDTO);
                          } catch (error) {
                            console.log(error);
                          }
                        },
                      },
                    ],
                  });
                }}
              />
              <Button
                expand
                size="small"
                label="Notify next in line"
                color="secondary"
                disabled={
                  !queue.line[1] ||
                  queue.line[1]?.bookType === "break" ||
                  didNotify
                }
                onClick={() => {
                  presentAlert(
                    "Notify the next person in line to let them know to head over?",
                    [
                      {
                        text: "Cancel",
                        role: "cancel",
                      },
                      {
                        text: "Yes",
                        handler: () => {
                          const customer = queue.line[1];
                          httpsCallable(
                            getFunctions(),
                            "nextInLineText"
                          )({
                            customerPhone:
                              customer.bookType !== "break" &&
                              customer.customer.phone,
                            providerName: provider.name,
                          });
                          setNotify(true);
                        },
                        role: "confirm",
                      },
                    ]
                  );
                }}
              />
            </>
          ) : (
            <>
              {book.service.process.length > 1 ? (
                <IonItem className="active-slots-processes" lines="none">
                  <IonButton
                    slot="start"
                    disabled={disableBtn || book.timestamps.length <= 1}
                    onClick={async () => {
                      setDisableBtn(true);
                      await updateDoc(
                        doc(db, "providers", provider.id, "queues", book.id),
                        {
                          timestamps: arrayRemove(
                            book.timestamps[book.timestamps.length - 1]
                          ),
                        }
                      );
                      setDisableBtn(false);
                    }}
                  >
                    <IonIcon icon={chevronBack} />
                  </IonButton>
                  <IonLabel style={{ textAlign: "center" }}>
                    {
                      book.service.process[book.timestamps.length - 1]
                        .description
                    }
                  </IonLabel>
                  {book.service.process.length !== book.timestamps.length ? (
                    <IonButton
                      slot="end"
                      disabled={disableBtn}
                      onClick={async () => {
                        setDisableBtn(true);
                        await updateDoc(
                          doc(db, "providers", provider.id, "queues", book.id),
                          {
                            timestamps: arrayUnion(new Date()),
                          }
                        );
                        sendOrScheduleText();
                        setDisableBtn(false);
                      }}
                    >
                      <IonIcon icon={chevronForward} />
                    </IonButton>
                  ) : (
                    <IonButton
                      slot="end"
                      onClick={() => {
                        presentAlert("Complete booking?", [
                          {
                            text: "Cancel",
                            role: "cancel",
                          },
                          {
                            text: "Complete",
                            handler: async () => {
                              try {
                                await addDoc(collection(db, "bookings"), {
                                  bookType: book.bookType,
                                  completed: new Date(),
                                  providerId: provider.id,
                                  categoryId: book.service.categoryId,
                                  bookData: {
                                    bookType: book.bookType,
                                    customerId: book?.customerId
                                      ? book.customerId
                                      : null,
                                    date: book.date,
                                    hasStarted: book.hasStarted,
                                    invite: book.invite,
                                    party: book.party,
                                    serviceId: book.serviceId,
                                    timeJoined: book.timeJoined.toDate(),
                                    timestamps: book.timestamps.map((t) =>
                                      t.toDate()
                                    ),
                                    frontTimestamp: book?.frontTimestamp
                                      ? book.frontTimestamp
                                      : null,
                                  },
                                } as BookingDTO);
                                await leaveLine({
                                  db,
                                  queue,
                                  provider,
                                  customerId: book.id,
                                });
                              } catch (error) {
                                console.log(error);
                              }
                            },
                          },
                        ]);
                      }}
                    >
                      <IonIcon icon={checkmark} />
                    </IonButton>
                  )}
                </IonItem>
              ) : (
                <Button
                  expand
                  size="small"
                  label="Complete"
                  color="primary"
                  onClick={() => {
                    presentAlert("Complete booking?", [
                      {
                        text: "Cancel",
                        role: "cancel",
                      },
                      {
                        text: "Complete",
                        handler: async () => {
                          try {
                            await addDoc(collection(db, "bookings"), {
                              bookType: book.bookType,
                              completed: new Date(),
                              providerId: provider.id,
                              categoryId: book.service.categoryId,
                              bookData: {
                                bookType: book.bookType,
                                customerId: book?.customerId
                                  ? book.customerId
                                  : null,
                                date: book.date,
                                hasStarted: book.hasStarted,
                                invite: book.invite,
                                party: book.party,
                                serviceId: book.serviceId,
                                timeJoined: book.timeJoined.toDate(),
                                timestamps: book.timestamps.map((t) =>
                                  t.toDate()
                                ),
                                frontTimestamp: book?.frontTimestamp
                                  ? book.frontTimestamp
                                  : null,
                              },
                            } as BookingDTO);
                            await leaveLine({
                              db,
                              queue,
                              provider,
                              customerId: book.id,
                            });
                          } catch (error) {
                            console.log(error);
                          }
                        },
                      },
                    ]);
                  }}
                />
              )}
              <IonGrid style={{ padding: 0 }}>
                <IonRow>
                  <IonCol style={{ padding: 0 }}>
                    <Button
                      expand
                      color="secondary"
                      label="Call/Text"
                      onClick={() => {
                        presentActionSheet({
                          header: "Call or text",
                          buttons: [
                            {
                              text: "Call",
                              handler: () => {
                                window.open(`tel:${book.customer.phone}`);
                              },
                            },
                            {
                              text: "Text",
                              handler: () => {
                                window.open(`sms:${book.customer.phone}`);
                              },
                            },
                          ],
                        });
                      }}
                      size="small"
                    />
                  </IonCol>
                  <IonCol style={{ padding: 0 }}>
                    <Button
                      expand
                      color="danger"
                      label="Kick"
                      onClick={() => {
                        presentAlert({
                          header: "Kick from line?",
                          message: `Are you sure you want to kick ${book.customer.name} from the line?`,
                          buttons: [
                            {
                              text: "Cancel",
                              role: "cancel",
                            },
                            {
                              text: "Yes",
                              handler: async () => {
                                await leaveLine({
                                  db,
                                  queue,
                                  provider,
                                  customerId: book.id,
                                });
                                await httpsCallable(
                                  getFunctions(app),
                                  "kick"
                                )(book.customer.phone);
                              },
                            },
                          ],
                        });
                      }}
                      size="small"
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </>
          )}
        </IonCardContent>
      </IonCard>
    </IonItemSliding>
  );
};

export default ActiveSlot;
