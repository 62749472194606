import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { Formik } from "formik";
import { Button, Header } from "components";
import * as Yup from "yup";
import { useState } from "react";
import { authState, onLogIn } from "../../../recoil/authState";
import { useRecoilValue } from "recoil";
import { Capacitor } from "@capacitor/core";

import "./login.css";

const LogIn = () => {
  const authStatus = useRecoilValue(authState);
  const navigation = useIonRouter();
  const [fail, setFail] = useState<string | undefined>(undefined);
  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string()
      .min(8, "Password must at least 8 characters.")
      .required("Please enter password"),
  });
  return (
    <IonPage>
      <Header label="Log in" />
      <IonContent>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ email: "", password: "" }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
              setFail(undefined);
              await onLogIn(values.email, values.password);

              setTimeout(() => {
                navigation.push(
                  !authStatus?.includes(0)
                    ? "/verify"
                    : !authStatus.includes(1)
                    ? "/payment"
                    : "/register",
                  "root"
                );
              }, 1000);
            } catch (error) {
              console.log(error);
              setFail((error as Error).message);
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="log-in-container" onSubmit={handleSubmit}>
              {fail ? (
                <IonText color="danger">
                  <p>Wrong email or password.</p>
                </IonText>
              ) : (
                <br />
              )}
              <IonItem className="ion-invalid" lines="full">
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  type="email"
                  name="email"
                  value={values.email}
                  onIonChange={handleChange}
                  onBlur={handleBlur}
                ></IonInput>
                {errors.email && touched.email && (
                  <IonNote slot="error">Please enter valid email.</IonNote>
                )}
              </IonItem>
              <IonItem className="ion-invalid" lines="full">
                <IonLabel position="floating">Password</IonLabel>
                <IonInput
                  type="password"
                  name="password"
                  value={values.password}
                  onIonBlur={handleBlur}
                  onIonChange={handleChange}
                ></IonInput>
                {errors.password && touched.password && (
                  <IonNote slot="error">{errors.password}</IonNote>
                )}
              </IonItem>
              <Button
                expand
                className="btn"
                type="submit"
                color="primary"
                label="Log In"
                onClick={() => {}}
                disabled={isSubmitting}
                loading={isSubmitting}
              />
            </form>
          )}
        </Formik>
        <br />
        <div style={{ textAlign: "center" }}>
          <a
            onClick={() => {
              if (Capacitor.isNativePlatform())
                window.open(
                  process.env.NODE_ENV === "development"
                    ? "http://localhost:3000/signup"
                    : "https://pro.mehu.app/signup"
                );
              else navigation.push("/signup", "root");
            }}
          >
            No account yet? Sign up!
          </a>
        </div>
        <br />
        <div style={{ textAlign: "center" }}>
          <a onClick={() => navigation.push("/forgot", "root")}>
            Forgot password?
          </a>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LogIn;
