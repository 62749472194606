import {
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
  useIonAlert,
} from "@ionic/react";
import { Button, Header } from "components";
import { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../App";
import { string } from "yup";

const ForgotPassword = () => {
  const [persentAlert] = useIonAlert();

  const [value, setValue] = useState("");
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);

  const validate = string().email().required();
  return (
    <IonPage>
      <Header label="Forgot password" />
      <IonContent>
        {err && (
          <IonText color="danger">
            <p>Please make sure email is typed correctly.</p>
          </IonText>
        )}
        <IonItem>
          <IonLabel position="floating">Email</IonLabel>
          <IonInput
            placeholder="Enter email"
            value={value}
            onIonChange={(e) => setValue(e.target.value as string)}
          ></IonInput>
        </IonItem>
        <Button
          expand
          loading={loading}
          color="primary"
          label="Send recovery email"
          onClick={async () => {
            try {
              setLoading(true);
              setErr(false);
              await validate.validate(value);
              await sendPasswordResetEmail(auth, value);
              persentAlert("Email sent.");
              setLoading(false);
            } catch (error) {
              setErr(true);
              setLoading(false);
            }
          }}
        />
      </IonContent>
    </IonPage>
  );
};


export default ForgotPassword;