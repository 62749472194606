/* eslint-disable turbo/no-undeclared-env-vars */
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  calendar,
  calendarOutline,
  home,
  homeOutline,
  personCircle,
  personCircleOutline,
} from "ionicons/icons";

import { initializeApp, getApp } from "firebase/app";
import { getFirestore, updateDoc, doc } from "firebase/firestore";
import {
  getAuth,
  initializeAuth,
  indexedDBLocalPersistence,
} from "firebase/auth";
import { Capacitor } from "@capacitor/core";
import { useEffect, useState } from "react";
import Home from "./pages/Home/Home";
import Calendar from "./pages/Calendar/Calendar";
import Account from "./pages/Account/Account";
import LogIn from "./pages/Auth/LogIn/LogIn";
import SignUp from "./pages/Auth/SignUp/SignUp";
import Payment from "./pages/Auth/Payment/Payment";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useRecoilValue } from "recoil";
import { authState, useAuth } from "./recoil/authState";
import VerifyEmail from "./pages/Auth/Verification/VerifyEmail";
import Verified from "./pages/Auth/Verification/Verified";
import Register from "./pages/Auth/Register/Register";
import { providerState, useGetProvider } from "apis";
import { Loading } from "components";
import Settings from "./pages/Account/Settings/Settings";
import Services from "./pages/Account/Services/Services";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

let stripePromise: Stripe | null;
export const getStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(process.env.REACT_APP_stripeAPI as string);
  }
  return stripePromise;
};

const firebaseConfig =
  process.env.NODE_ENV === "development"
    ? {
        apiKey: process.env.REACT_APP_apiKey,
        authDomain: process.env.REACT_APP_authDomain,
        projectId: process.env.REACT_APP_projectId,
        storageBucket: process.env.REACT_APP_storageBucket,
        messagingSenderId: process.env.REACT_APP_messagingSenderId,
        appId: process.env.REACT_APP_appId,
        measurementId: process.env.REACT_APP_measurementId,
      }
    : {
        apiKey: process.env.REACT_APP_apiKey,
        authDomain: process.env.REACT_APP_authDomain,
        databaseURL: process.env.REACT_APP_databaseURL,
        projectId: process.env.REACT_APP_projectId,
        storageBucket: process.env.REACT_APP_storageBucket,
        messagingSenderId: process.env.REACT_APP_messagingSenderId,
        appId: process.env.REACT_APP_appId,
        measurementId: process.env.REACT_APP_measurementId,
      };
export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = !Capacitor.isNativePlatform()
  ? getAuth()
  : initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    });

setupIonicReact();

const App: React.FC = () => {
  const [selectedTab, onSelectChange] = useState<string>();
  const userAuth = useRecoilValue(authState);
  const provider = useRecoilValue(providerState);
  async function getSelectedTab() {
    //@ts-ignore
    onSelectChange(await document.getElementById("tabs")?.selectedTab);
  }

  useAuth();
  useGetProvider(db, auth.currentUser?.uid, userAuth);

  useEffect(() => {
    getStripe();
    getSelectedTab();
  }, []);

  if (userAuth === undefined || provider === undefined) return <Loading />;

  if (userAuth.length !== 3) {
    return (
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route exact path="/login" component={LogIn} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/verify" component={VerifyEmail} />
            <Route exact path="/verified" component={Verified} />
            <Route exact path="/payment" component={Payment} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/forgot" component={ForgotPassword} />

            <Redirect to="/login" />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    );
  }

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/home" component={Home} />
            <Route exact path="/calendar" component={Calendar} />
            <Route exact path="/account" component={Account} />
            <Route exact path="/account/settings" component={Settings} />
            <Route exact path="/account/services" component={Services} />

            <Redirect to="/home" />
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton
              tab="home"
              href="/home"
              onClick={() => onSelectChange("home")}
            >
              <IonIcon icon={selectedTab === "home" ? home : homeOutline} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>
            <IonTabButton
              tab="calendar"
              href="/calendar"
              onClick={() => onSelectChange("calendar")}
            >
              <IonIcon
                icon={selectedTab === "calendar" ? calendar : calendarOutline}
              />
              <IonLabel>Calendar</IonLabel>
            </IonTabButton>
            <IonTabButton
              tab="account"
              href="/account"
              onClick={() => onSelectChange("account")}
            >
              <IonIcon
                icon={
                  selectedTab === "account" ? personCircle : personCircleOutline
                }
              />
              <IonLabel>Account</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
