import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonFabList,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import { Button, Modal } from "components";
import { ellipsisVertical, personAdd, pause } from "ionicons/icons";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { BookType, BreakType, IProvider, IQueue } from "interfaces";
import { allQueuesState, getInLine } from "apis";
import { useRecoilValue } from "recoil";
import { db } from "../../App";
import * as yup from "yup";

const FabButton: React.FC<{ provider: IProvider; queue: IQueue | string }> = ({
  provider,
  queue,
}) => {
  const [manualAddValue, setManualAddValue] = useState({
    name: "",
    phone: "",
    numOfPeople: "",
    serviceId: "",
  });
  const [breakValue, setBreakValue] = useState("");
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [breakModalOpen, setBreakModalOpen] = useState(false);
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);

  const validateAdd = yup.object({
    name: yup.string().required(),
    phone: yup.string().min(9).required(),
    numOfPeople: yup.number().required(),
    serviceId: yup.string().required(),
  });
  const validateBreak = yup.number().required();

  const queues = useRecoilValue(allQueuesState);

  if (!queues) return null;

  return (
    <>
      <IonFab slot="fixed" vertical="bottom" horizontal="end">
        <IonFabButton>
          <IonIcon icon={ellipsisVertical} />
        </IonFabButton>
        <IonFabList side="top">
          <IonFabButton onClick={() => setAddModalOpen(true)}>
            <IonIcon icon={personAdd} />
          </IonFabButton>
          <IonFabButton onClick={() => setBreakModalOpen(true)}>
            <IonIcon icon={pause} />
          </IonFabButton>
        </IonFabList>
      </IonFab>

      <Modal title="Manual add" isOpen={addModalOpen} setOpen={setAddModalOpen}>
        <div>
          {err && (
            <IonText color="danger">
              <p>Please make sure all fields are valid.</p>
            </IonText>
          )}
          <IonList>
            <IonItem lines="full">
              <IonLabel position="floating">Name</IonLabel>
              <IonInput
                value={manualAddValue.name}
                onIonChange={(e) => {
                  setManualAddValue({
                    ...manualAddValue,
                    name: e.target.value as string,
                  });
                }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Enter phone number:</IonLabel>
              <PhoneInput
                name="phone"
                placeholder="(000) 000-0000"
                className="phone-input"
                defaultCountry="US"
                value={manualAddValue.phone}
                onChange={(num) => {
                  setManualAddValue({
                    ...manualAddValue,
                    phone: num === undefined ? "" : num,
                  });
                }}
              />
            </IonItem>
            <IonItem lines="full">
              <IonLabel>Number of people:</IonLabel>
              <IonInput
                placeholder="Type here"
                type="number"
                value={manualAddValue.numOfPeople}
                onIonChange={(e) => {
                  setManualAddValue({
                    ...manualAddValue,
                    numOfPeople: e.target.value as string,
                  });
                }}
              ></IonInput>
            </IonItem>
            <IonItem lines="full">
              <IonSelect
                value={manualAddValue.serviceId}
                onIonChange={(e) =>
                  setManualAddValue({
                    ...manualAddValue,
                    serviceId: e.target.value,
                  })
                }
                placeholder="Select service"
              >
                {provider.services.map((s) => (
                  <IonSelectOption key={s.id} value={s.id}>
                    {s.title}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonList>
          <Button
            expand
            loading={loading}
            color="primary"
            label="Add"
            onClick={async () => {
              setErr(false);
              try {
                setLoading(true);
                await validateAdd.validate(manualAddValue);
                await getInLine({
                  db,
                  queues,
                  queue,
                  provider,
                  data: {
                    bookType: BookType.MANUAL,
                    hasStarted: false,
                    date: typeof queue === "string" ? queue : queue.head.date,
                    invite: null,
                    party: {
                      isApproved: true,
                      numOfPeople: parseInt(manualAddValue.numOfPeople),
                    },
                    serviceId: manualAddValue.serviceId || "",
                    timeJoined: new Date(),
                    timestamps: [],
                    customer: {
                      name: manualAddValue.name,
                      phone: manualAddValue.phone,
                    },
                  },
                });
                setLoading(false);
                setAddModalOpen(false);
                setManualAddValue({
                  name: "",
                  phone: "",
                  numOfPeople: "",
                  serviceId: "",
                });
              } catch (error) {
                console.log(error)
                setErr(true);
                setLoading(false);
              }
            }}
          />
        </div>
      </Modal>
      <Modal
        title="Add break"
        isOpen={breakModalOpen}
        setOpen={setBreakModalOpen}
      >
        <div>
          {err && (
            <IonText color="danger">
              <p>Please enter valid value.</p>
            </IonText>
          )}
          <IonItem>
            <IonLabel>Duration</IonLabel>
            <IonInput
              type="number"
              placeholder="in mins"
              value={breakValue}
              onIonChange={(e) => setBreakValue(e.target.value as string)}
            ></IonInput>
          </IonItem>
          <Button
            expand
            loading={loading}
            color="primary"
            label="Add"
            onClick={async () => {
              setErr(false);
              try {
                setLoading(true);
                await validateBreak.validate(breakValue);
                await getInLine({
                  db,
                  queues,
                  queue,
                  provider,
                  data: {
                    bookType: "break",
                    breakType: BreakType.CUSTOM,
                    date: typeof queue === "string" ? queue : queue.head.date,
                    mins: parseInt(breakValue),
                    timeJoined: new Date(),
                  },
                });
                setLoading(false);
                setBreakModalOpen(false);
                setBreakValue("");
              } catch (error) {
                setErr(true);
                setLoading(false);
              }
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default FabButton;
