import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonText,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
import {
  categoriesState,
  providerState,
  useGetCategories,
  useGetProvider,
} from "apis";
import { Button, Header, Loading, Modal } from "components";
import { useRecoilValue, useRecoilState } from "recoil";
import { auth, db } from "../../../App";
import { useState } from "react";
import { IService, ServiceDTO } from "interfaces";
import { deleteDoc, doc, addDoc, collection } from "firebase/firestore";
import { remove, add } from "ionicons/icons";
import * as yup from "yup";

const Services = () => {
  const nav = useIonRouter();
  const [presentAlert] = useIonAlert();

  const [provider, setProvider] = useRecoilState(providerState);
  const categories = useRecoilValue(categoriesState);

  const [loading, setLoading] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [currService, setCurrService] = useState<IService>();
  const [services, setServices] = useState([
    {
      title: "",
      description: "",
      category: "",
      process: {
        isOneStep: true,
        oneStepEstMins: undefined as undefined | string,
        steps: [
          {
            step: 1,
            description: "",
            estMins: undefined as undefined | string,
            placeholder: "Wash hair",
          },
          {
            step: 2,
            description: "",
            estMins: undefined as undefined | string,
            placeholder: "Dry hair",
          },
        ],
      },
    },
  ]);
  const [servicesErr, setServicesErr] = useState<string>();

  const validateServices = yup.object({
    services: yup.array().of(
      yup.object({
        title: yup.string().required(),
        description: yup.string().required(),
        category: yup.string().required(),
        process: yup.object({
          isOneStep: yup.boolean().required(),
          oneStepEstMins: yup.number().when("isOneStep", {
            is: true,
            then: (schema) => schema.required(),
          }),
          steps: yup.array().when("isOneStep", {
            is: false,
            then: (schema) =>
              schema.of(
                yup.object({
                  description: yup.string().required(),
                  estMins: yup.number().required(),
                })
              ),
          }),
        }),
      })
    ),
  });

  useGetProvider(db, auth.currentUser?.uid);
  useGetCategories(db);

  if (!provider || !categories) return <Loading />;

  return (
    <IonPage>
      <Header label="Services" />
      <IonContent>
        <IonList>
          {provider.services.map((service) => (
            <IonItem
              key={service.id}
              lines="full"
              button
              detail
              onClick={() => {
                setCurrService(service);
                setDetailsModalOpen(true);
              }}
            >
              <IonLabel>
                <h3>
                  {service.title}{" "}
                  {service.process.length > 1 &&
                    `(${service.process.length} steps)`}
                </h3>
                <p>{service.description}</p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
        <Button
          expand
          color="primary"
          label="Add Services"
          onClick={() => {
            setAddModalOpen(true);
          }}
        />

        <Modal
          isOpen={detailsModalOpen}
          setOpen={setDetailsModalOpen}
          title="Service details"
        >
          <div>
            <h3>{currService?.title}</h3>
            <p>{currService?.description}</p>
            <IonList>
              {currService && currService.process.length > 1 ? (
                <>
                  {currService.process.map((s) => (
                    <IonItem key={s.step}>
                      <IonLabel>
                        <h3>
                          Step {s.step}: {s.description}
                        </h3>
                      </IonLabel>
                    </IonItem>
                  ))}
                </>
              ) : null}
            </IonList>
            <Button
              expand
              color="danger"
              label="Delete"
              onClick={() => {
                presentAlert(
                  "Are you sure you want to permanently delete this service?",
                  [
                    {
                      text: "Cancel",
                      role: "cancel",
                    },
                    {
                      text: "Yes",
                      handler: async () => {
                        await deleteDoc(
                          doc(
                            db,
                            "providers",
                            provider.id,
                            "services",
                            currService?.id || ""
                          )
                        );
                        setDetailsModalOpen(false);
                        nav.goBack();
                      },
                    },
                  ]
                );
              }}
            />
          </div>
        </Modal>
        <Modal
          isOpen={addModalOpen}
          setOpen={setAddModalOpen}
          title="Add Services"
        >
          <div className="slides-container">
            {servicesErr && (
              <IonText color="danger">
                <p>{servicesErr}</p>
              </IonText>
            )}
            <div>
              {services.map((service, index) => (
                <div key={index}>
                  <IonList>
                    <IonItem lines="full">
                      <IonLabel position="floating">Service name</IonLabel>
                      <IonInput
                        placeholder="Short haircut, long braids, wax, etc..."
                        onIonChange={(e) =>
                          setServices(
                            services.map((r, i) =>
                              index === i
                                ? {
                                    ...r,
                                    title: e.target.value as string,
                                  }
                                : r
                            )
                          )
                        }
                      ></IonInput>
                    </IonItem>
                    <IonItem lines="full">
                      <IonLabel position="floating">Description</IonLabel>
                      <IonInput
                        placeholder=""
                        onIonChange={(e) =>
                          setServices(
                            services.map((r, i) =>
                              index === i
                                ? {
                                    ...r,
                                    description: e.target.value as string,
                                  }
                                : r
                            )
                          )
                        }
                      ></IonInput>
                    </IonItem>
                    <IonItem lines="full">
                      <IonLabel>Category of service</IonLabel>
                      <IonSelect
                        placeholder="Select"
                        onIonChange={(e) =>
                          setServices(
                            services.map((r, i) =>
                              index === i
                                ? {
                                    ...r,
                                    category: e.target.value as string,
                                  }
                                : r
                            )
                          )
                        }
                      >
                        {categories.map((category) => (
                          <IonSelectOption
                            key={category.id}
                            value={category.id}
                          >
                            {category.title}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                    <p className="subtitle">Steps:</p>
                    <IonRadioGroup
                      onIonChange={(e) =>
                        setServices(
                          services.map((s, i) =>
                            index === i
                              ? {
                                  ...s,
                                  process: {
                                    ...s.process,
                                    isOneStep: e.target.value,
                                  },
                                }
                              : s
                          )
                        )
                      }
                      value={service.process.isOneStep}
                    >
                      <IonItem lines="none">
                        <IonRadio slot="start" value={true}></IonRadio>
                        <IonLabel>
                          <h3>Single step process</h3>
                          <p>i.e. haircut, eyebrow threading, etc.</p>
                        </IonLabel>
                      </IonItem>
                    </IonRadioGroup>
                    <IonItem disabled={!service.process.isOneStep}>
                      <IonLabel>Estimated time</IonLabel>
                      <IonInput
                        type="number"
                        placeholder="in mins"
                        onIonChange={(e) =>
                          setServices(
                            services.map((s, i) =>
                              i === index
                                ? {
                                    ...s,
                                    process: {
                                      ...s.process,
                                      oneStepEstMins:
                                        e.target.value === ""
                                          ? undefined
                                          : (e.target.value as undefined),
                                    },
                                  }
                                : s
                            )
                          )
                        }
                      ></IonInput>
                    </IonItem>
                    <IonRadioGroup
                      onIonChange={(e) =>
                        setServices(
                          services.map((s, i) =>
                            index === i
                              ? {
                                  ...s,
                                  process: {
                                    ...s.process,
                                    isOneStep: e.target.value,
                                  },
                                }
                              : s
                          )
                        )
                      }
                      value={service.process.isOneStep}
                    >
                      <IonItem lines="none">
                        <IonRadio slot="start" value={false}></IonRadio>
                        <IonLabel>
                          <h3>Multi-step process</h3>
                          <p>i.e. braidng (wash hair, dry hair, then braid)</p>
                        </IonLabel>
                      </IonItem>
                    </IonRadioGroup>
                    {service.process.steps.map((step, idx) => (
                      <IonList key={idx}>
                        <IonItem disabled={service.process.isOneStep}>
                          <IonLabel>Step {idx + 1}:</IonLabel>
                          <IonInput
                            placeholder={step.placeholder}
                            onIonChange={(e) =>
                              setServices(
                                services.map((s, i) =>
                                  i === index
                                    ? {
                                        ...s,
                                        process: {
                                          ...s.process,
                                          steps: s.process.steps.map(
                                            (st, ind) =>
                                              ind === idx
                                                ? {
                                                    ...st,
                                                    description: e.target
                                                      .value as string,
                                                  }
                                                : st
                                          ),
                                        },
                                      }
                                    : s
                                )
                              )
                            }
                          ></IonInput>
                        </IonItem>
                        <IonItem
                          lines="full"
                          disabled={service.process.isOneStep}
                        >
                          <IonLabel>Estimated time</IonLabel>
                          <IonInput
                            type="number"
                            placeholder="in mins"
                            onIonChange={(e) =>
                              setServices(
                                services.map((s, i) =>
                                  i === index
                                    ? {
                                        ...s,
                                        process: {
                                          ...s.process,
                                          steps: s.process.steps.map(
                                            (st, ind) =>
                                              ind === idx
                                                ? {
                                                    ...st,
                                                    estMins:
                                                      e.target.value === ""
                                                        ? undefined
                                                        : (e.target
                                                            .value as undefined),
                                                  }
                                                : st
                                          ),
                                        },
                                      }
                                    : s
                                )
                              )
                            }
                          ></IonInput>
                        </IonItem>
                      </IonList>
                    ))}
                    <IonItem lines="full" disabled={service.process.isOneStep}>
                      <div className="horizontal-btns">
                        {service.process.steps.length > 2 && (
                          <IonButton
                            fill="clear"
                            onClick={() =>
                              setServices(
                                services.map((s, ind) =>
                                  index === ind
                                    ? {
                                        ...s,
                                        process: {
                                          ...s.process,
                                          steps: s.process.steps.filter(
                                            (_, i) =>
                                              i !== s.process.steps.length - 1
                                          ),
                                        },
                                      }
                                    : s
                                )
                              )
                            }
                          >
                            <IonIcon slot="icon-only" icon={remove}></IonIcon>
                          </IonButton>
                        )}

                        <IonButton
                          fill="clear"
                          onClick={() => {
                            setServices(
                              services.map((s, ind) =>
                                index === ind
                                  ? {
                                      ...s,
                                      process: {
                                        ...s.process,
                                        steps: [
                                          ...s.process.steps,
                                          {
                                            step: s.process.steps.length + 1,
                                            description: "",
                                            estMins: undefined,
                                            placeholder: "Braid hair",
                                          },
                                        ],
                                      },
                                    }
                                  : s
                              )
                            );
                          }}
                        >
                          <IonIcon slot="icon-only" icon={add}></IonIcon>
                        </IonButton>
                      </div>
                    </IonItem>
                  </IonList>
                </div>
              ))}
              <div className="horizontal-btns">
                {services.length > 1 && (
                  <IonButton
                    fill="clear"
                    onClick={() =>
                      setServices(
                        services.filter((_, i) => i === services.length - 1)
                      )
                    }
                  >
                    <IonIcon slot="icon-only" icon={remove}></IonIcon>
                  </IonButton>
                )}

                <IonButton
                  fill="clear"
                  onClick={() =>
                    setServices([
                      ...services,
                      {
                        title: "",
                        description: "",
                        category: "",
                        process: {
                          isOneStep: true,
                          oneStepEstMins: undefined,
                          steps: [
                            {
                              step: 1,
                              description: "",
                              estMins: undefined,
                              placeholder: "Wash hair",
                            },
                            {
                              step: 2,
                              description: "",
                              estMins: undefined,
                              placeholder: "Dry hair",
                            },
                          ],
                        },
                      },
                    ])
                  }
                >
                  <IonIcon slot="icon-only" icon={add}></IonIcon>
                </IonButton>
              </div>
            </div>

            <Button
              className="next-btn"
              expand
              loading={loading}
              label="Add Services"
              onClick={async () => {
                setLoading(true);
                setServicesErr(undefined);
                try {
                  await validateServices.validate({ services });
                  services.forEach(
                    async (s) =>
                      await addDoc(
                        collection(db, "providers", provider.id, "services"),
                        {
                          categoryId: s.category,
                          description: s.description,
                          title: s.title,
                          process: s.process.isOneStep
                            ? [
                                {
                                  step: 1,
                                  description: s.description,
                                  estMins: parseInt(
                                    s.process.oneStepEstMins || "0"
                                  ),
                                },
                              ]
                            : s.process.steps.map((step) => ({
                                step: step.step,
                                description: step.description,
                                estMins: parseInt(step.estMins || "0"),
                              })),
                          totalEstMins: s.process.isOneStep
                            ? parseInt(s.process.oneStepEstMins || "0")
                            : s.process.steps.reduce(
                                (prev, curr) =>
                                  prev + parseInt(curr.estMins || "0"),
                                0
                              ),
                        } as ServiceDTO
                      )
                  );
                  setAddModalOpen(false);
                  nav.goBack();
                } catch (error) {
                  if ((error as yup.ValidationError).errors === undefined)
                    setServicesErr(
                      "Please check all inputs are filled correctly."
                    );
                  else setServicesErr("Error occured. Please try again later.");
                }
                setLoading(false);
              }}
              color="primary"
            />
          </div>
        </Modal>
      </IonContent>
    </IonPage>
  );
};

export default Services;
