import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonProgressBar,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Button } from "components";
import { auth } from "../../../App";
import { createCheckoutSession } from "../../../stripe/checkoutSession";
import { useEffect, useState } from "react";

import "./payment.css";

const Payment = () => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [int, setInt] = useState<NodeJS.Timeout>();
  const [toggle, setToggle] = useState(false);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (trigger)
      if (progress === 0 && !toggle) {
        setInt(
          setInterval(() => {
            setProgress((prevProgress) => prevProgress + 0.01);
          }, 75)
        );
        setToggle(true);
      }

    if (progress > 0.85) clearInterval(int);
  }, [int, progress, toggle, trigger]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Payment</IonTitle>
          {trigger && <IonProgressBar value={progress}></IonProgressBar>}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container">
          {err && (
            <IonText color="danger">
              <p>Error occured. Please try again later.</p>
            </IonText>
          )}
          <br />
          <h2>No appointments, no disappointments</h2>
          <p>Get the all-in-one app for personal care professionals:</p>
          <ul>
            <li>
              Increase productivity with a tested, non-traditional, task
              management-like booking system
            </li>
            <li>
              Increase clientele with our thousands of active users and
              automated curation system
            </li>
            <li>
              Increase revenue with more clientele plus our Surge feature that
              leverages busy times
            </li>
          </ul>
          <h4>Free for 60 days</h4>
          <h4>then $29.99/month</h4>
          <br />
          <Button
            expand
            color="primary"
            label="Get started"
            onClick={async () => {
              try {
                setErr(false);
                setLoading(true);
                await createCheckoutSession(auth.currentUser?.uid || "");
                setTrigger(true);
              } catch (error) {
                setErr(true);
                setLoading(false);
              }
            }}
            loading={loading}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};
export default Payment;
