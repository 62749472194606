import {
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonPage,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { Button, Header } from "components";
import { Formik } from "formik";
import { useState } from "react";
import { onSignUp } from "../../../recoil/authState";
import * as Yup from "yup";

import "./signUp.css";

const SignUp = () => {
  const [fail, setFail] = useState<string | undefined>(undefined);

  const navigation = useIonRouter();

  const validationSchema = Yup.object({
    email: Yup.string().required(),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref("email")])
      .required(),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters.")
      .required("Password must be at least 8 characters."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match.")
      .required(),
  });
  return (
    <IonPage>
      <Header label="Sign up" />
      <IonContent>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            email: "",
            confirmEmail: "",
            password: "",
            confirmPassword: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
              setFail(undefined);
              await onSignUp(values.email, values.password);
              navigation.push("/verify", "forward");
            } catch (error) {
              setFail((error as Error).message);
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="sign-up-container" onSubmit={handleSubmit}>
              {fail ? (
                <IonText color="danger">
                  <p>Email already exists.</p>
                </IonText>
              ) : (
                <br />
              )}
              <IonItem className="ion-invalid" lines="full">
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  type="email"
                  name="email"
                  value={values.email}
                  onIonChange={handleChange}
                  onBlur={handleBlur}
                ></IonInput>
                {errors.email && touched.email && (
                  <IonNote slot="error">Please enter valid email.</IonNote>
                )}
              </IonItem>
              <IonItem className="ion-invalid" lines="full">
                <IonLabel position="floating">Confirm email</IonLabel>
                <IonInput
                  type="email"
                  name="confirmEmail"
                  value={values.confirmEmail}
                  onIonChange={handleChange}
                  onBlur={handleBlur}
                ></IonInput>
                {errors.confirmEmail && touched.confirmEmail && (
                  <IonNote slot="error">Emails must match.</IonNote>
                )}
              </IonItem>
              <IonItem className="ion-invalid" lines="full">
                <IonLabel position="floating">Password</IonLabel>
                <IonInput
                  type="password"
                  name="password"
                  value={values.password}
                  onIonChange={handleChange}
                  onBlur={handleBlur}
                ></IonInput>
                {errors.password && touched.password && (
                  <IonNote slot="error">{errors.password}</IonNote>
                )}
              </IonItem>
              <IonItem className="ion-invalid" lines="full">
                <IonLabel position="floating">Confirm password</IonLabel>
                <IonInput
                  type="password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onIonChange={handleChange}
                  onBlur={handleBlur}
                ></IonInput>
                {errors.confirmPassword && touched.confirmPassword && (
                  <IonNote slot="error">Passwords must match.</IonNote>
                )}
              </IonItem>
              <Button
                expand
                className="btn"
                type="submit"
                color="primary"
                label="Sign up"
                onClick={() => {}}
                disabled={isSubmitting}
                loading={isSubmitting}
              />
              <div style={{ textAlign: "center" }}>
                <a onClick={() => navigation.push("/login", "root")}>
                  Already have an account?
                </a>
              </div>
            </form>
          )}
        </Formik>
      </IonContent>
    </IonPage>
  );
};

export default SignUp;
