import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonPage,
  IonReorderGroup,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useRecoilValue } from "recoil";
import { notTodayQueueState, providerState, useGetProvider } from "apis";
import { Loading } from "components";
import { updateDoc, doc } from "firebase/firestore";
import { auth, db } from "../../App";
import { useEffect, useState } from "react";
import moment from "moment";
import { IQueue } from "interfaces";
import Slot from "../../components/Slot/Slot";
import FabButton from "../../components/FabButton/FabButton";
import { settingsOutline } from "ionicons/icons";
import "./calendar.css";

const Calendar = () => {
  const provider = useRecoilValue(providerState);
  const queues = useRecoilValue(notTodayQueueState);

  const [loading, setLoading] = useState(false);
  const [currDate, setCurrDate] = useState(
    moment().add(1, "days").format().split("T")[0]
  );
  const [currQueue, setCurrQueue] = useState<IQueue>();

  useGetProvider(db, auth.currentUser?.uid);
  useEffect(() => {
    if (provider && queues)
      setCurrQueue(queues.find((q) => q.head.date === currDate));
  }, [currDate, provider, queues]);

  if (!provider || !queues) return <Loading />;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Calendar</IonTitle>
          <IonButtons slot="end">
            <IonButton>
              <IonIcon slot="icon-only" icon={settingsOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {provider.daysCanBookAhead ? (
          <>
            <div className="calendar-container">
              <IonDatetime
                min={moment().add(1, "day").format()}
                max={moment().add(provider.daysCanBookAhead, "days").format()}
                value={currDate}
                onIonChange={(e) =>
                  setCurrDate((e.target.value as string).split("T")[0])
                }
                presentation="date"
                className="calendar"
              ></IonDatetime>
            </div>
            <div style={{ padding: "1rem" }}>
              {currQueue !== undefined &&
                currQueue.line.some(
                  (b) => b.bookType !== "break" && !b.party.isApproved
                ) && (
                  <IonText color="danger">
                    <p>You have unapproved spots.</p>
                  </IonText>
                )}
              {currQueue ? (
                <IonReorderGroup
                  disabled={loading}
                  onIonItemReorder={async (e) => {
                    setLoading(true);
                    const line = [...currQueue.head.line];
                    e.detail.complete(line);
                    await updateDoc(
                      doc(
                        db,
                        "providers",
                        provider.id,
                        "queues",
                        currQueue.head.id
                      ),
                      { line }
                    );
                    setLoading(false);
                  }}
                >
                  {currQueue.line.map((book, index) => (
                    <Slot
                      key={index}
                      index={index}
                      book={book}
                      showStart={false}
                      provider={provider}
                      queue={currQueue}
                    />
                  ))}
                </IonReorderGroup>
              ) : (
                <p style={{ textAlign: "center" }}>
                  No queue for this day yet.
                </p>
              )}
            </div>
            <FabButton
              provider={provider}
              queue={currQueue ? currQueue : currDate}
            />
          </>
        ) : (
          <p style={{ textAlign: "center" }}>
            Booking ahead is not enabled. You can change it in calendar
            settings.
          </p>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Calendar;
