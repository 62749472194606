import { IonContent, IonPage, IonSpinner, useIonRouter } from "@ionic/react";
import { useEffect } from "react";
import { RouteComponentProps, useLocation } from "react-router";
import { auth } from "../../../App";
import { authState, handleEmailVerify } from "../../../recoil/authState";
import { useRecoilValue } from "recoil";
import { Loading } from "components";

const Verified = () => {
  const location = useLocation();
  const code = location.search.split(/=|&/)[3];
  const navigation = useIonRouter();
  const currentAuth = useRecoilValue(authState);

  useEffect(() => {
    async function verification() {
      await handleEmailVerify(code);
      if (auth.currentUser) {
        await auth.currentUser.getIdToken(true);
        navigation.push("/payment", "root");
        window.location.reload();
      } else {
        navigation.push("/login", "root");
      }
    }
    if (currentAuth !== undefined) verification();
  }, [code, navigation, currentAuth]);

  if (currentAuth === undefined) return <Loading />;

  return (
    <IonPage>
      <IonContent>
        <div>
          <p>Email verified. Redirecting...</p>
          <IonSpinner name="crescent" color="primary" />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Verified;
